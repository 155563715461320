<template>
  <v-col cols="12" class="px-0">
    <!-- event remarks text/pargraph -->
    <v-row class="mx-0">
      <p style="max-width:260px ;" class="body-1" v-if="singleRFP.details">{{singleRFP.details}}</p>
      <p class="subtitle-1 platinum--text text--darken-3" v-else>No remarks given</p>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CitiesExclude",
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP"
    })
  }
};
</script>
