<template>
  <v-dialog v-model="editRemarksDialog" persistent scrollable max-width="600px">
    <v-card>
      <v-card-title class="pb-3 px-2 px-sm-6">
        <v-row>
          <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
            <h2 class="headline text-left">Edit Event Remarks</h2>
          </v-col>
          <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
            <v-btn icon color="secondary" @click="closeDialog">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-col cols="12" md="12">
            <v-textarea outlined name="input-7-4" label="Event Remarks" :counter="250" :rules="[v => (v || '' ).length <= 250 || 'Remarks description must be 250 characters or less']" v-model="singleRFP.details">
            </v-textarea>
          </v-col>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-start px-3">
            <v-btn class="px-12" color="secondary" tile outlined depressed bottom @click="closeDialog">Cancel</v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-end px-0">
            <v-btn class="px-12" color="secondary" tile depressed bottom right @click="submit">Submit</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import RFPApi from "../../../../services/rfp.service";

export default {
  name: "EditRemarks",
  props: ["editRemarksDialog"],
  data() {
    return {
      details: ''
    }
  },
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    }),
  },
  mounted() {
    this.details = this.singleRFP.details;
  },
  methods: {
    closeDialog() {
      this.$emit('editRemarks', false);
    },
    submit(){
      var count = this.singleRFP.details.length
      console.log(count)
      if(count <= 250){
        this.updateRemarks()
      }else{
        this.$store.dispatch("notify/setSnackbarMessage",
          {snackBarText: 'Remarks description must be 250 characters or less', snackBarColor: "red",});
          
      }
    },
    updateRemarks() {
      RFPApi.updateRFP(this.singleRFP).then((res) => {
        console.log(res)
        this.$store.dispatch("rfp/setSingleRFP", this.singleRFP);
        this.closeDialog();
        this.$store.dispatch("notify/setSnackbarMessage",
          {snackBarText: 'RFP Remarks Successfully Updated!', snackBarColor: "success",});
      }, error => {
        console.log(error)
      });
    }
  }
}
</script>

<style scoped>

</style>
