<template>
  <v-dialog
    v-model="editEventSummaryDialog"
    persistent
    scrollable
    max-width="420px"
  >
    <v-card>
      <v-card-title class="pb-3 px-2 px-sm-6">
        <v-row>
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="headline text-left">Edit Event Summary</h2>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="secondary" @click="closeDialog">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="pb-0 px-2 px-sm-6 mb-n10 mt-2">
        <v-col cols="8"  class="mt-2">
          <span class="text-left subtitle-1">No. Delegates:</span>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="singleRFP.noOfDelegates"
            class="text-left subtitle-2"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-0 px-2 px-sm-6 mb-n10">
        <v-col cols="8"  class="mt-2">
          <span class="text-left subtitle-1">No. Hotel Rooms:</span>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="singleRFP.noOfHotelRooms"
            class="text-left subtitle-2"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-0 px-2 px-sm-6 mb-n10">
        <v-col cols="8"  class="mt-2">
          <span class="text-left subtitle-1">No. Breakout Rooms:</span>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="singleRFP.noOfBreakoutRooms"
            class="text-left subtitle-2"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-0 px-2 px-sm-6 mb-n10">
        <v-col cols="8"  class="mt-2">
          <span class="text-left subtitle-1">Hybrid Solutions:</span>
        </v-col>
        <v-col cols="4">
          <v-text-field
          :value="'No'"
            outlined
            dense
            readonly  
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-0 px-2 px-sm-6  mb-n10">
    <v-col cols="8"  class="mt-2">
      <span class="text-left subtitle-1">Start Date:</span>
    </v-col>
    <v-col cols="4">
      <v-dialog v-model="datePickerDialog" max-width="290">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="singleRFP.startDate"
            readonly
            v-on="on"
            @click="datePickerDialog = true"
            outlined  
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="singleRFP.startDate"
          no-title
          @input="datePickerDialog = false"
          class="date-picker-input"
        ></v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>

  <v-row class="px-0 px-2 px-sm-6">
    <v-col cols="8"  class="mt-2">
      <span class="text-left subtitle-1">End Date:</span>
    </v-col>
    <v-col cols="4">
      <v-dialog v-model="datePickerDialog" max-width="290">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="singleRFP.endDate"
            readonly
            v-on="on"
            @click="datePickerDialog = true"
            outlined  
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="singleRFP.endDate"
          no-title
          @input="datePickerDialog = false"
         
        ></v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>


      <v-card-actions class="px-6">
        <v-row
          class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-3"
          >
            <v-btn
              class="px-12"
              color="secondary"
              tile
              outlined
              depressed
              bottom
              @click="closeDialog"
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-12"
              color="secondary"
              tile
              depressed
              bottom
              right
              @click="submit"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters } from "vuex";
  import RFPApi from "../../../../services/rfp.service";
  export default {
    name: "EditEventSummary",
    props: ["editEventSummaryDialog"],
    data() {
      return {
        details: "",
      
      };
    },
    computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    }),
  },
//   watch:{
//     singleRFP(val){
//         console.log(val)
//       this.id = val._id;
//       this.editedNoOfDelegates = val.noOfDelegates;
//       this.editedNoOfHotelRooms = val.noOfHotelRooms;
//       this.editedNoOfBreakoutRooms = val.noOfBreakoutRooms;
//       this.editedHybridSolutions = val.hybridSolutions;
//       this.editedendDate = val.endDate;
//       this.editedstartDate = val.startDate;
//     }
//   },
    methods: {
  closeDialog() {
    this.$emit("editEventSummary", false);
  },
  async submit() {
    try {
  
    RFPApi.updateRFPEvent(this.singleRFP).then((res) => {
        console.log(res)
        this.$store.dispatch("rfp/setSingleRFP", this.singleRFP);
        this.closeDialog();
        this.$store.dispatch("notify/setSnackbarMessage",
          {snackBarText: 'RFP Remarks Successfully Updated!', snackBarColor: "success",});
      }, error => {
        console.log(error)
      });
    
      this.closeDialog();
    } catch (error) {
      console.error(error);
    }
  }
},

  };
</script>
<style scoped></style>
