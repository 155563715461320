<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">{{ singleRFP.title }}</div>
            <div
              class="
                d-flex
                flex-row
                align-center
                justify-start
                subtitle-1
                font-weight-light
              "
            >
              <span>{{
                  singleRFP.duration > 1
                    ? `${singleRFP.duration} Days`
                    : `${singleRFP.duration} Day`
                }}</span>
              <span class="mx-3">•</span>
              <span>{{ singleRFP.status }}</span>
            </div>
          </template>
          <div>
            <v-overlay
              :value="overlay"
              opacity="1"
              color="white"
              z-index="0"
              absolute="absolute"
            >
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div>
          <v-card-text>
            <v-row class="d-flex flex-row align-start justify-space-between">
              <!-- rfp status column -->
              <v-col cols="12" md="9" class="rfp-status-container">
                <Status/>
              </v-col>
              <!-- end of rfp status column -->
              <!-- rfp status dropdown -->
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column align-end justify-center px-0"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="px-6 mx-0 my-0"
                      color="secondary"
                      v-on="on"
                      tile
                      depressed
                      :disabled="$route.meta.isDisable"
                      bottom
                    >Change Status
                    </v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in status"
                      :key="index"
                      @click="changeFn(item.val)"
                    >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <!-- end of rfp status dropdown -->
              <!-- rfp details column -->
              <v-col cols="12" md="7" lg="8">
                <v-row
                  class="d-flex flex-row align-start justify-space-between"
                >

                <v-col cols="12" sm="6">
                    <v-row
                      class="d-flex flex-row align-start justify-space-between"
                    >
                     <!-- event summary column -->
                
                      <v-col cols="12" md="10">
                        <h2 class="title primary--text">Event Summary</h2>
                       
                      </v-col>
                      <v-col cols="12" md="2">
                        <font-awesome-icon class="edit-icon pa-1" :icon="['fas', 'pen']" color="#313638" size="lg"
                                           style="cursor: pointer" @click="toggleEditEventSummary(true)"/>
                      </v-col>
                      <v-col cols="12">
                        <EventSummary/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- end of event summary column -->
                  <!-- event city/country exclusion column -->
                  <v-col cols="12" sm="6">
                    <h2 class="title primary--text">
                      Counties/Cities to Exclude
                    </h2>
                    <CitiesExclude/>
                  </v-col>
                  <!-- end of event city/country exclusion column -->
                  <!-- rfp/event remarks column -->
                  <v-col cols="12" sm="6">
                    <v-row
                      class="d-flex flex-row align-start justify-space-between"
                    >
                      <!-- event remarks column -->
                      <v-col cols="12" md="10">
                        <h2 class="title primary--text">Event Remarks
                        </h2>
                      </v-col>
                      <v-col cols="12" md="2">
                        <font-awesome-icon class="edit-icon pa-1" :icon="['fas', 'pen']" color="#313638" size="lg"
                                           style="cursor: pointer" @click="toggleEditRemarks(true)"/>
                      </v-col>
                      <v-col cols="12">
                        <EventRemarks/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- end of rfp/event remarks column -->
                  <!-- rfp/event remarks column -->
                  <v-col cols="12" sm="6" v-if="singleRFP.proposal">
                    <v-row
                      class="d-flex flex-row align-start justify-space-between"
                    >
                      <!-- event remarks column -->
                      <v-col cols="12">
                        <h2 class="title primary--text">
                          Event Proposal Document/s
                        </h2>
                        <v-row
                          v-if="singleRFP.proposal.length == 0"
                          class="mx-0 my-3"
                        >
                          <span
                            class="
                              text-left
                              subtitle-1
                              platinum--text
                              text--darken-3
                            "
                          >No proposals submitted</span
                          >
                        </v-row>
                        <v-chip
                          v-for="(proposal, i) in singleRFP.proposal"
                          :key="i"
                          class="proposal-link ma-0 mt-4 mb-0"
                          large
                          close
                          color="secondary"
                          label
                          text-color="white"
                          close-icon="mdi-download"
                          @click:close="openProposalLink(proposal)"
                          @click="openProposalLink(proposal)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span class="text-truncate"
                          >Proposal Document
                            {{
                              singleRFP.proposal.length > 0 ? i + 1 : ""
                            }}</span
                          >
                        </v-chip>
                        <v-chip
                          v-for="(proposal, i) in singleRFP.supportingDoc"
                          :key="i"
                          class="proposal-link ma-0 mt-4 mb-0"
                          large
                          close
                          color="secondary"
                          label
                          text-color="white"
                          close-icon="mdi-download"
                          @click:close="openProposalLink(proposal)"
                          @click="openProposalLink(proposal)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span class="text-truncate"
                          >Supporting Document
                            {{
                              singleRFP.supportingDoc.length > 0 ? i + 1 : ""
                            }}</span
                          >
                        </v-chip>
                      </v-col>
                      <v-col cols="12">
                        <v-btn block style="background-color: #35a7ff;color: white;" @click="uploadDialog">
                          <v-icon left dark>
                            mdi-cloud-upload
                          </v-icon>
                          Reupload Documents
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- end of rfp/event remarks column -->
                  <!-- shortlisted cities and venues selection -->
                  <v-col cols="12">
                    <v-row
                      class="d-flex flex-row align-start justify-space-between"
                    >
                      <!-- shortlisted cities column -->
                      <v-col cols="12">
                        <v-row
                          class="
                            d-flex
                            flex-row
                            align-start
                            justify-center
                            mx-0
                          "
                        >
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex flex-column align-start px-0"
                          >
                            <h2 class="title primary--text">
                              Shortlisted Cities
                            </h2>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            class="d-flex flex-column align-center px-0"
                          >
                            <v-autocomplete
                              :items="computedCities"
                              v-model="shortListedCityName"
                              :disabled="$route.meta.isDisable"
                              item-text="city"
                              outlined
                              required
                              return-object
                              hide-details="auto"
                              :label="`Search for Cities`"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- end shortlisted cities and venues selection -->
                </v-row>
              </v-col>
              <!-- end of rfp details column -->
              <!-- organiser details column -->
              <v-col
                class="
                  d-flex
                  flex-column
                  align-center align-md-end
                  justify-center
                "
                cols="12"
                md="5"
                lg="4"
              >
                <OrganiserDetails/>
              </v-col>
              <!-- end of organiser details column -->
            </v-row>
            <v-row class="d-flex flex-row align-start justify-space-between">
              <v-col cols="12" md="8">
<!--                <Cities/>-->
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row align-start justify-space-between">
              <v-col
                cols="12"
                md="12"
                class="d-flex flex-column align-start px-0"
              >
                <h2 class="title secondery--text">
                  Shortlist Venues
                </h2>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="selectedCity"
                  :items="computedCityList"
                  item-text="city"
                  outlined
                  required
                  return-object
                  :label="`City List`"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selectedVenue"
                  :items="venues"
                  item-text="venueName"
                  outlined
                  required
                  return-object
                  :label="`Search for venue`"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  color="secondary"
                  style="font-size: 11px;width: 100%;margin-top: 10%"
                  tile
                  block
                  @click="addNewVenue"
                >Assign to city
                </v-btn>
              </v-col>
              <v-col cols="12" md="4"></v-col>
              <v-col
                cols="12"
                md="12"
                class="d-flex flex-column align-start px-0"
              >
                <h2 class="title primary--text">
                  Selected Venues
                </h2>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row align-start justify-space-between" style="margin-top: 20%">
              <RfpCard/>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card height="90vh" style="overflow-y: scroll;padding-right: 10px;padding-left: 10px">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
              <h2 class="headline text-left">Reupload Proposal Documents</h2>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
              <v-btn class="dialog-close-btn" icon color="grey lighten-1" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                label="RFP Document"
                placeholder="Choose Document"
                prepend-icon
                append-icon="mdi-paperclip"
                clear-icon="mdi-close"
                truncate-length="14"
                clearable
                show-size
                outlined
                required
                hide-details="auto"
                v-model="file2"
                @change="fileChanged"
                ref="fileClearUpload"
                :key="fileUploadKey">
              </v-file-input>
              <v-chip
                v-for="(proposal, i) in singleRFP.proposal"
                :key="i"
                class="proposal-link ma-0 mt-4 mb-0"
                style="height: 30px;margin-left: 10%;width: 80%"
                large
                close
                color="secondary"
                label
                text-color="white"
                close-icon="mdi-download"
                @click:close="openProposalLink(proposal)"
                @click="openProposalLink(proposal)"
              >
                <v-icon left>mdi-file</v-icon>
                <span class="text-truncate">Proposal Document {{
                    singleRFP.proposal.length > 0 ? i + 1 : ""
                  }}</span>
              </v-chip>
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input
                label="Supporting Document"
                placeholder="Choose Document"
                prepend-icon
                append-icon="mdi-paperclip"
                clear-icon="mdi-close"
                truncate-length="14"
                clearable
                show-size
                outlined
                required
                hide-details="auto"
                v-model="file3"
                @change="supDocChanged"
                :key="fileUploadKey">
              </v-file-input>
              <v-chip
                v-for="(proposal, i) in singleRFP.supportingDoc"
                :key="i"
                class="proposal-link ma-0 mt-4 mb-0"
                style="height: 30px;margin-left: 10%;width: 80%"
                large
                close
                color="secondary"
                label
                text-color="white"
                close-icon="mdi-download"
                @click:close="openProposalLink(proposal)"
                @click="openProposalLink(proposal)"
              >
                <v-icon left>mdi-file</v-icon>
                <span class="text-truncate">Supporting Document {{
                    singleRFP.supportingDoc.length > 0 ? i + 1 : ""
                  }}</span>
              </v-chip>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="12" md="3" style="padding-left: 50px">
                  <h4>
                    Venue
                  </h4>
                </v-col>
                <v-col cols="12" md="1" style="margin-top: -18px">
                  <v-checkbox v-model="activeVenues"></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-combobox
                    :disabled="!activeVenues && !activeBoth"
                    :items="venues"
                    v-model="shortListedVenues"
                    label="Select Venues"
                    item-text="venueName"
                    outlined
                    multiple
                    allow-overflow
                    clearable
                    deletable-chips
                    chips></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" style="padding-left: 50px">
                  <h4>
                    City
                  </h4>
                </v-col>
                <v-col cols="12" md="1" style="margin-top: -18px">
                  <v-checkbox v-model="activeCities"></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-combobox
                    :disabled="!activeCities && !activeBoth"
                    :items="computedCities"
                    v-model="shortListedCities"
                    label="Select Cities"
                    item-text="city"
                    outlined
                    multiple
                    allow-overflow
                    clearable
                    deletable-chips
                    chips></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" style="padding-left: 50px">
                  <h4>
                    Both
                  </h4>
                </v-col>
                <v-col cols="12" md="1" style="margin-top: -18px">
                  <v-checkbox v-model="activeBoth"></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">

                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-row
            class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
              <v-btn class="px-8" color="secondary" @click="dialog = false" tile outlined depressed bottom>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0">
              <v-btn class="px-8 mx-0" color="secondary" tile depressed bottom right @click="updateRFP">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditRemarks :editRemarksDialog="editRemarksDialog" @editRemarks="toggleEditRemarks(false)"/>
    <EditEventSummary :editEventSummaryDialog="editEventSummaryDialog" @editEventSummary="toggleEditEventSummary(false)"/>
  </v-container>
</template>

<script>
import RFPApicalls from "../../../services/rfp.service";
import cityApicalls from "../../../services/citySubscribers.service";
import venueApicalls from "../../../services/venue.service";
import {mapGetters} from "vuex";
import Status from "./RFPView/Status";
import OrganiserDetails from "./RFPView/OrganiserDetails";
import EventSummary from "./RFPView/EventSummary";
import CitiesExclude from "./RFPView/CitiesExclude";
import EventRemarks from "./RFPView/EventRemarks";
// import Cities from "./RFPView/Cities";
import RfpCard from "./RFPView/CompareCity";
import EditRemarks from "./RFPView/EditRemarks";
import EditEventSummary from "./RFPView/EditEventSummary";
import rfpApicalls from "../../../services/rfp.service";

export default {
  name: "RFPView",
  components: {
    EditRemarks,
    EditEventSummary,
    OrganiserDetails,
    Status,
    EventSummary,
    CitiesExclude,
    EventRemarks,
    // Cities,
    RfpCard
  },
  data() {
    return {
      file: false,
      file2: null,
      file3: null,
      supDoc: false,
      fileUploadKey: Number(new Date()),
      activeVenues: false,
      activeCities: false,
      activeBoth: false,
      dialog: false,
      cities: [],
      venues: [],
      shortListedCityName: null,
      shortListedVenues: null,
      shortListedCities: null,
      val: {},
      overlay: true,
      editRemarksDialog: false,
      editEventSummaryDialog: false,
      status: [
        {
          name: "Draft",
          val: "draft",
        },
        {
          name: "Open",
          val: "open",
        },
        {
          name: "Pending",
          val: "pending",
        },
        {
          name: "Completed",
          val: "completed",
        },
      ],
      item: {
        id: 2,
        trophy: false,
        organisation: "TED Corporation",
        title: "TEDx Manila 2020",
        representative: {
          name: "Sage Rodriguez",
          email: "sage@tedcorp.com",
          date: "01/12/2019",
          logo: [
            "https://st3.depositphotos.com/13159112/17145/v/1600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg",
          ],
        },
        type: "Match",
        duration: 1,
        cities: "3",
        venues: "4",
        delegates: "330",
        hotel: "400",
        breakout: "15",
        exhibition: "190",
        topic: "Technology",
        status: "Pending",
      },
      selectedCity: null,
      selectedVenue: null,
    };
  },
  computed: {
    ...mapGetters({
      singleRFP: "rfp/getSingleRFP",
    }),
    computedShortListedCities() {
      return this.singleRFP.cityShortList.map((x) => x.cityId);
    },
    computedShortListedCityNames() {
      return this.singleRFP.cityShortList.map((x) => x.city);
    },
    computedVenues() {
      // for (let i = 0; i < this.singleRFP.cityShortList.length; i++) {
      //   for (let j = 0; j < this.singleRFP.cityShortList[i].venue.length; j++) {
      //     venues.push(this.singleRFP.cityShortList[i].venue[j].venueName);
      //   }
      // }
      let venues = this.venues.map((x) => {
        return {
          ...x,
          venueName: this.capitalize(x.venueName),
        };
      });
      return venues;
    },
    computedCities() {
      // console.log(this.computedShortListedCities);

      let cities = this.cities.filter(
        (x) => !this.computedShortListedCities.includes(x._id)
      );

      let cityExc = cities.map((x) => {
        return {
          ...x,
          city: this.capitalize(x.city),
        };
      });

      return cityExc;
    },
    computedCityList() {
      let cities = this.cities.filter(
        (x) => this.computedShortListedCities.includes(x._id)
      );

      let cityExc = cities.map((x) => {
        return {
          ...x,
          city: this.capitalize(x.city),
        };
      });

      return cityExc;
    },
    computedOptions() {
      let options = [];
      options.push({icon: 'arrows-alt-v', label: 'No. of Meeting Rooms Offered'});
      options.push({icon: 'arrows-alt-v', label: 'No. of Hotel Rooms Offered'});
      options.push({icon: 'arrows-alt-v', label: 'No. of Breakout Rooms Offered'});
      options.push({icon: 'arrows-alt-v', label: 'Sustainability Certificate'});
      options.push({icon: 'arrows-alt-v', label: 'Distance to Main Station'});
      options.push({icon: 'arrows-alt-v', label: 'Distance to City Centre'});
      options.push({icon: 'arrows-alt-v', label: 'Exhibition Space'});
      options.push({icon: 'arrows-alt-v', label: 'Extra'});
      options.push({icon: 'arrows-alt-v', label: 'Parking'});
      options.push({icon: 'arrows-alt-v', label: 'Free Wi-Fi'});
      options.push({icon: 'arrows-alt-v', label: 'Accessible'});
      options.push({icon: 'arrows-alt-v', label: 'Gym'});
      options.push({icon: 'arrows-alt-v', label: 'Air-condition'});
      options.push({icon: 'arrows-alt-v', label: 'Swimming Pool'});
      options.push({icon: 'arrows-alt-v', label: 'AV Equipment'});
      options.push({icon: 'arrows-alt-v', label: 'Projector'});
      options.push({icon: 'arrows-alt-v', label: 'Video Camera'});
      options.push({icon: 'arrows-alt-v', label: 'Catering'});
      options.push({icon: 'arrows-alt-v', label: 'Breakfast'});
      options.push({icon: 'arrows-alt-v', label: 'Brunch'});
      options.push({icon: 'arrows-alt-v', label: 'Lunch'});
      options.push({icon: 'arrows-alt-v', label: 'AV Costs'});
      options.push({icon: 'arrows-alt-v', label: 'Catering Costs'});
      options.push({icon: 'arrows-alt-v', label: 'Room Rate(Single Use)'});
      options.push({icon: 'arrows-alt-v', label: 'Hybrid Meeting PKG'});
      options.push({icon: 'arrows-alt-v', label: 'DDR Rate'});
      options.push({icon: 'arrows-alt-v', label: 'Total Offer'});
      options.push({icon: 'arrows-alt-v', label: 'Venue has selected (success fee)'});
      return options;
    }
  },
  watch: {
    shortListedCityName(val) {
      // console.log(val);
      //addCity
      let payload = {
        rfpId: this.$route.params.id,
        cityId: val._id,
        organiserUser: this.singleRFP.user._id,
      };
      RFPApicalls.setCityForRFP(payload).then((re) => {
        payload = {
          rfpId: this.$route.params.id,
          organiserUser: this.singleRFP.user._id,
        }
        if (this.singleRFP.rfptype === 'Per Venue') {
          this.shortListedCityName = null;
          this.$store.dispatch("rfp/setSingleRFP", re.data);
        } else {
          RFPApicalls.setCombinedVenue(payload).then((res) => {
            this.shortListedCityName = null;
            this.$store.dispatch("rfp/setSingleRFP", res.data);
          });
        }
        // RFPApicalls.setCombinedVenue(payload).then((res) => {
        //   let payload2 = {
        //     snackBarText: `New city, ${val.city} successfully added to the shortlisted cities`,
        //     snackBarColor: "success",
        //   };
        //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
        //
        //   this.shortListedCityName = null;
        //   this.$store.dispatch("rfp/setSingleRFP", res.data);
        // });
        // this.items = res.data;
      });
      // .catch(err => {
      //   console.log(err);
      // });
      //clear Val
    },
    singleRFP() {
      this.shortListedVenues = [];
      this.shortListedCities = [];
      for (let i = 0; i < this.singleRFP.cityShortList.length; i++) {
        for (let j = 0; j < this.singleRFP.cityShortList[i].venue.length; j++) {
          this.shortListedVenues.push(this.singleRFP.cityShortList[i].venue[j]);
        }
        this.shortListedCities.push(this.singleRFP.cityShortList[i]);
      }
      console.log('detecting')
      console.log(this.singleRFP)
    },
    shortListedVenues(val) {
      console.log(val);
      let alreadyExist = false;
      for (let i = 0; i < val.length; i++) {
        alreadyExist = false;

        for (let l = 0; l < this.singleRFP.cityShortList.length; l++) {
          if (val[i].city === this.singleRFP.cityShortList[l]._id) {
            alreadyExist = true;
          }
        }
        if (!alreadyExist) {
          for (let j = 0; j < this.cities.length; j++) {
            if (val[i].city === this.cities[j]._id) {
              // this.singleRFP.cityShortList.push(this.cities[j]);
              break;
            }
          }
        }
      }
    },
    selectedCity: {
      immediate: true,
      handler(val) {
        let payload = {
          city: val.city,
          country: val.country,
        };
        venueApicalls.getVenueProfileByCity(payload).then((res) => {
          this.venues = res.data;
        });
      }
    }
  },
  mounted() {
    this.overlay = true;

    // console.log("Sen2");
    let payload = {
      _id: this.$route.params.id,
    };
    RFPApicalls.getRFPById(payload).then((res) => {
      // this.val = res.data;
      this.$store.dispatch("rfp/setSingleRFP", res.data);
      // this.items = res.data;
    });
    // .catch(err => {
    //   console.log(err);
    // });

    cityApicalls.getAllCityName().then((res) => {
      this.cities = res.data;
      this.overlay = false;
    });
    venueApicalls.getAllVenueProfile().then((res) => {
      this.venues = res.data.content.data;
      // console.log(this.venues)
    });
  },
  beforeDestroy() {
    this.$store.dispatch("rfp/clearState");
  },
  methods: {
    async updateRFP() {
      this.uploadDocuments();
      this.identifyNewCities();
      this.addNewCities();
      this.identifyAndSaveNewVenues();
      this.dialog = false;
      let payload2 = {
        snackBarText: "Successfully updated!",
        snackBarColor: "success",
      };
      await this.$store.dispatch("notify/setSnackbarMessage", payload2);
    },
    identifyNewCities() {
      let exists = false;
      for (let i = 0; i < this.shortListedVenues.length; i++) {
        exists = false;
        for (let j = 0; j < this.shortListedCities.length; j++) {
          if (this.shortListedVenues[i].city === this.shortListedCities[j]._id) {
            exists = true;
            break
          }
        }
        if (!exists) {
          for (let j = 0; j < this.cities.length; j++) {
            if (this.shortListedVenues[i].city === this.cities[j]._id) {
              this.shortListedCities.push(this.cities[j]);
              break;
            }
          }
        }
      }
    },
    addNewCities() {
      let exist;
      // let addedCount = 0;
      for (let i = 0; i < this.shortListedCities.length; i++) {
        exist = false;
        for (let j = 0; j < this.singleRFP.cityShortList.length; j++) {
          if (this.singleRFP.cityShortList[j]._id === this.shortListedCities[i]._id || this.singleRFP.cityShortList[j].cityId === this.shortListedCities[i]._id) {
            exist = true;
            break;
          }
        }
        if (!exist) {
          //save new city
          let payload = {
            rfpId: this.$route.params.id,
            cityId: this.shortListedCities[i]._id,
            organiserUser: this.singleRFP.user._id,
          };
          RFPApicalls.setCityForRFP(payload).then((re) => {
            this.shortListedCityName = null;
            payload = {
              rfpId: this.$route.params.id,
              organiserUser: this.singleRFP.user._id,
            }
            if (this.singleRFP.rfptype === 'Per Venue') {
              this.shortListedCityName = null;
              this.$store.dispatch("rfp/setSingleRFP", re.data);
            } else {
              RFPApicalls.setCombinedVenue(payload).then((res) => {
                this.shortListedCityName = null;
                this.$store.dispatch("rfp/setSingleRFP", res.data);
              });
            }
            // addedCount++;
            // this.items = res.data;
          });
        }
      }
      // if (addedCount > 0) {
      //   let payload2 = {
      //     snackBarText: addedCount + " new cities are added to the shortlist!",
      //     snackBarColor: "success",
      //   };
      //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
      // }
    },
    identifyAndSaveNewVenues() {
      let savedVenues = [];
      // let savedCount = 0;
      for (let i = 0; i < this.singleRFP.cityShortList.length; i++) {
        for (let j = 0; j < this.singleRFP.cityShortList[i].venue.length; j++) {
          savedVenues.push(this.singleRFP.cityShortList[i].venue[j]);
        }
      }
      let saved;
      for (let i = 0; i < this.shortListedVenues.length; i++) {
        saved = false;
        for (let j = 0; j < savedVenues.length; j++) {
          if (savedVenues[j]._id === this.shortListedVenues[i]._id) {
            saved = true;
            break;
          }
        }
        if (!saved) {
          console.log('==============')
          console.log(this.shortListedVenues[i])
          console.log('==============')
          let payload = {
            rfpId: this.singleRFP._id,
            cityId: this.shortListedVenues[i].city,
            venueProfileId: this.shortListedVenues[i]._id,
            organiserUser: this.singleRFP.user._id,
          };
          rfpApicalls.setVenueForRFP(payload).then((res) => {
            savedVenues.push(this.shortListedVenues[i]);
            this.$store.dispatch("rfp/setSingleRFP", res.data);
            // savedCount++;
          });
        }
      }
      // if (savedCount > 0) {
      //   let payload2 = {
      //     snackBarText: savedCount + " new venues are successfully added!",
      //     snackBarColor: "success",
      //   };
      //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
      // }
    },
    fileChanged(file) {
      let size = file ? file.size / 1024 / 1024 : 0;
      if (size > 2) {
        let payload = {
          snackBarText: "Your file is too big! Please select a file under 2MB",
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.file2 = null;
        this.fileUploadKey = Number(new Date());
      } else {
        if (file) {
          this.file = file;
        } else {
          this.file = false;
        }
      }
    },
    supDocChanged(file) {
      let size = 0;
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          const tmpfile = file[i].size / 1024 / 1024;
          size = size + tmpfile;
        }
      }
      // let size = file ? file.size / 1024 / 1024 : 0;
      if (size > 2) {
        let payload = {
          snackBarText: "Your supporting document is too big! Please select a file under 2MB",
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.file3 = null;

        this.fileUploadKey = Number(new Date());
      } else {
        if (file) {
          this.supDoc = file;
        } else {
          this.supDoc = false;
        }
      }
    },
    uploadDocuments() {
      if (this.file || this.supDoc) {
        var formData = new FormData();
        if (this.file) {
          formData.append("file", this.file);
        }
        if (this.supDoc) {
          formData.append("sup_", this.supDoc);
        }
        formData.set("rfp", this.singleRFP._id);
        console.log(formData)
        rfpApicalls.uploadRFPDocs(formData).then((res) => {
          console.log(res);
          this.dialog = false;
          let payload2 = {
            snackBarText: "Documents are uploaded!",
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.$store.dispatch("rfp/setSingleRFP", res.data);
        });
      }
    },
    capitalize(name) {
      // console.log(name)
      let res, firstChar, tail;

      name = name.trim().toLowerCase();
      res = name.split(" ");
      for (var i = 0; i < res.length; i++) {
        firstChar = res[i].substring(0, 1);
        firstChar = firstChar.toUpperCase();
        tail = tail = res[i].substring(1);
        res[i] = firstChar + tail;
        if (i == 0) {
          name = res[i];
        } else {
          name = name + " " + res[i];
        }
      }
      return name;
    },
    changeFn(val) {
      let payload = {
        id: this.$route.params.id,
        status: val,
      };
      RFPApicalls.setStatusForRFP(payload).then((res) => {
        this.shortListedVenueName = null;
        let payload2 = {
          snackBarText: `RFP status changed successfully to '${this.capitalize(
            val
          )}'`,
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.$store.dispatch("rfp/setSingleRFP", res.data);
      });
    },
    openProposalLink(proposal) {
      window.open(proposal, "_blank");
    },
    toggleEditRemarks(val) {
      this.editRemarksDialog = val;
    },
    toggleEditEventSummary(val) {
      this.editEventSummaryDialog = val;
    },
    uploadDialog() {
      this.dialog = true;
    },
    async addNewVenue() {
      if (this.selectedVenue._id !== undefined && this.selectedCity._id !== undefined) {
        let payload = {
          rfpId: this.singleRFP._id,
          cityId: this.selectedCity._id,
          venueProfileId: this.selectedVenue._id,
          organiserUser: this.singleRFP.user._id,
        };
        await rfpApicalls.setVenueForRFP(payload).then((res) => {
          let payload2 = {
            snackBarText: `New venue, ${
              this.selectedVenue.venueName
            } successfully added to the shortlisted city, ${this.capitalize(
              this.selectedCity.city
            )}`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.$store.dispatch("rfp/setSingleRFP", res.data);
          this.selectedVenue = null;
          this.selectedCity = null;
        });
      }
    }
  },
};
</script>
<style scoped>
.rfp-status-container {
  width: 100%;
  overflow: auto hidden;
  /* scrollbar size fix for firefox */
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.rfp-status-container::-webkit-scrollbar {
  width: 0.5rem;
}

.rfp-status-container::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f0f0f0;
}

.rfp-status-container::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.proposal-link {
  width: 100%;
}

.proposal-link >>> .v-chip__content {
  width: 100%;
}

.proposal-link >>> .v-chip__content .v-icon.mdi-download {
  margin-left: auto;
}
</style>
